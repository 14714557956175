var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"dayoff-form"},[_c('div',{staticClass:"dayoff-form__input-segment"},[_c('RadioButtonGroup',{attrs:{"name":"permitsType","title":"Jenis","required":"","rules":"required","custom-messages":{
          required: 'Jenis harus diisi'
        },"options":_vm.permitTypeOptions},model:{value:(_vm.payload.permitsType),callback:function ($$v) {_vm.$set(_vm.payload, "permitsType", $$v)},expression:"payload.permitsType"}})],1),_c('div',{staticClass:"dayoff-form__input-segment"},[_c('InputHeader',{attrs:{"label-for":"startDate_endDate","title":"Waktu Sakit / Izin / Cuti","required":""}}),_c('div',{staticClass:"dayoff-form__start-end-date"},[_c('InputDateTime',{attrs:{"name":"startDate","type":"date","min-datetime":null,"rules":"required|date_compare:lte,@endDate","custom-messages":{
            required: 'Tanggal harus diisi',
            date_compare: 'Tanggal mulai harus lebih dulu dari tanggal akhir'
          },"placeholder":"Tanggal Mulai","value":_vm.startDateISOString,"value-zone":"UTC+7"},on:{"change":_vm.onStartDateChanged}}),_c('InputDateTime',{attrs:{"name":"endDate","type":"date","min-datetime":_vm.startDateISOString,"rules":"required","custom-messages":{
            required: 'Tanggal harus diisi'
          },"placeholder":"Tanggal Akhir","value":_vm.endDateISOString,"value-zone":"UTC+7"},on:{"change":_vm.onEndDateChanged}})],1)],1),_c('ValidationObserver',{staticClass:"dayoff-form__input-segment",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('InputHeader',{attrs:{"label-for":"startDate_endDate","title":"Yuk kasih tau dulu","required":""}}),_vm._l((_vm.permitAcknowledgmentOptions),function(ack,index){return _c('ValidationProvider',{key:index,staticClass:"dayoff-form__stakeholder-checkbox",attrs:{"tag":"p","rules":{ required: { allowFalse: false }}},nativeOn:{"!click":function($event){return _vm.onAcknowledgementOptionChecked(ack)}}},[_c('input',{attrs:{"multiple":"","type":"checkbox","name":("permitAcknowledge[" + index + "]")},domProps:{"value":_vm.isAcknowledgementOptionChecked(ack),"checked":_vm.isAcknowledgementOptionChecked(ack)}}),_c('label',{staticClass:"dark:text-white"},[_vm._v(" "+_vm._s(ack)+" ")])])}),(_vm.isSomePermitAcknowledgementEmpty(errors))?_c('p',{staticClass:"form-input__error-hint mt-2"},[_vm._v(" Oops, kamu belum ngasih tahu kesemua stakeholder. "),_c('br'),_vm._v(" Yuk kasih tahu dulu, lalu checklist formnya. ")]):_vm._e()]}}],null,true)}),_c('div',{staticClass:"dayoff-form__input-segment"},[_c('InputTextarea',{attrs:{"name":"reason","rows":"5","placeholder":_vm.notePlaceholder,"title":"Alasan Sakit / Izin / Cuti","required":"","rules":"required","custom-messages":{
          required: 'Alasan harus diisi'
        }},model:{value:(_vm.payload.note),callback:function ($$v) {_vm.$set(_vm.payload, "note", $$v)},expression:"payload.note"}})],1),_c('div',{staticClass:"dayoff-form__input-segment"},[_c('DayOffImageInput',{ref:"dayOffImageInput",attrs:{"name":"evidence","title":"Upload Evidence","rules":"required|mimes:application/pdf,image/*|size:5120","accept":"application/pdf|image/*","custom-messages":{
          required: 'Evidence harus diisi',
          mimes: 'File harus berupa gambar / file PDF',
          size: 'File tidak boleh lebih dari 5MB'
        }},on:{"change:url":_vm.onEvidenceImageURLChanged,"change:file":_vm.onEvidenceImageFileChanged},scopedSlots:_vm._u([{key:"subtitle",fn:function(){return [_c('span',{staticClass:" text-gray-600"},[_vm._v(" Misal: file/scan surat dokter, capture chat izin ke atasan, Surat Cuti yang sudah TTE ")])]},proxy:true}],null,true)})],1),_c('div',{staticClass:"dayoff-form__actions"},[_c('button',{staticClass:"dayoff-form__btn-cancel",on:{"click":_vm.onCancel}},[_vm._v(" Batal ")]),_c('button',{staticClass:"dayoff-form__btn-submit",on:{"click":function($event){return handleSubmit(_vm.beforeSubmit)}}},[_vm._v(" Kirim ")])])],1),_c('Dialog',{attrs:{"show":_vm.showSubmissionDialog,"max-width":"480px"}},[_c('Submission',_vm._b({on:{"success":_vm.onSubmissionSuccess,"error":_vm.onSubmissionError,"cancel":_vm.onCloseConfirmationDialog,"close":_vm.onCloseConfirmationDialog}},'Submission',{ payload: _vm.payload },false))],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }